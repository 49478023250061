
/* colors */
:root {
    --colorDebug_red:         rgba(255,000,000,0.2);
    --colorDebug_orange:      rgba(255,128,000,0.2);
    --colorDebug_green:       rgba(000,255,000,0.2);
    --colorDebug_blue:        rgba(000,000,255,0.2);
    --colorStyle_black:       rgba(000,000,000,1.0);


    --colorTheme_Grey:        rgba(128,128,128,1.0);
    --colorTheme_GreyLigth:   rgba(192,192,192,1.0);
    --colorTheme_GreyShad:    rgba(224,224,224,1.0);
    --colorTheme_-ARedVine:   rgba(119,27,18,1.0);
    --colorTheme_-ARedVineLigth: rgba(119,27,18,0.5);
    --colorTheme_-ARedVineShad: rgba(119,27,18,0.2);

    --colorTheme_Magic:       rgba(159,26,133,1.0);
    --colorTheme_MagicLigth:  rgba(159,26,133,0.5);
    --colorTheme_MagicShad:   rgba(159,26,133,0.2);
    --colorTheme_Siren:       rgba(151,8,204,1.0);
    --colorTheme_SirenLigth:  rgba(151,8,204,0.5);
    --colorTheme_SirenShad:   rgba(151,8,204,0.2);

    --colorTheme_Violet:      rgba(222,19,126,1.0);
    --colorTheme_VioletLigth: rgba(222,19,126,0.8);
    --colorTheme_VioletShad:  rgba(222,19,126,0.3);
    --colorTheme_-ARed:       rgba(238,53,36,1.0);
    --colorTheme_-ARedLigth:  rgba(238,53,36,0.7);
    --colorTheme_-ARedShad:   rgba(238,53,36,0.3);

    --colorTheme_Orange:      rgba(222,67,19,1.0);
    --colorTheme_OrangeLigth: rgba(222,67,19,0.5);
    --colorTheme_OrangeShad:  rgba(222,67,19,0.2);
    --colorTheme_Carrot:      rgba(244,158,59,1.0);
    --colorTheme_CarrotLigth: rgba(244,158,59,0.5);
    --colorTheme_CarrotShad:  rgba(244,158,59,0.2);

    --colorTheme_Sky:         rgba(32,162,222,1.0);
    --colorTheme_SkyLigth:    rgba(32,162,222,0.5);
    --colorTheme_SkyShad:     rgba(32,162,222,0.2);


    --color-KL:               #00a88e;
    --color-KL_Light:         rgba(0,168,142,0.6);
    --color-KL_Dark:          rgba(1,92,72,0.8);
    --color-KL_GreenLight:    #77F93B;
    --color-KL_Standard:      #0996dc;
    --color-KL_Plus:          #8233ff;
    --color-KL_Premium:       #381e76;
    --color-DW:               rgba(104,185,5,1.0);
    --color-ITH_Pink:         rgba(175,8,208,1.0);
    --color-ITH_PinkLight:    rgba(175,8,208,0.7);
    --color-ITH_Orange:       rgba(232,82,0,1.0);
    --color-ITH_OrangeLight:  rgba(232,82,0,0.7);
}

/* button (+theme) */
.StyledKey_Magic            {background: linear-gradient(160deg, var(--colorTheme_Magic) 0%, var(--colorTheme_Magic) 50%, var(--colorTheme_MagicLigth) 100%); border: 2px solid var(--colorTheme_Magic); color: #FFF; text-shadow: 0 0 16px #FFF; font-weight: 700; box-shadow: 0 0 3px var(--colorTheme_Magic);}
.StyledKeyOut_Magic         {color: var(--colorTheme_Magic); font-weight: 700; box-shadow: 0 0 3px var(--colorTheme_Magic); border: 2px solid var(--colorTheme_Magic);}
.StyledKey_Violet           {background: linear-gradient(180deg, var(--colorTheme_Violet) 0%, var(--colorTheme_Violet) 50%, var(--colorTheme_VioletLigth) 100%); border: 2px solid var(--colorTheme_Violet); color: #FFF; text-shadow: 0 0 16px #FFF; font-weight: 700; box-shadow: 0 0 3px var(--colorTheme_Violet);}
.StyledKeyOut_Violet        {color: var(--colorTheme_Violet); font-weight: 700; box-shadow: 0 0 3px var(--colorTheme_Violet); border: 2px solid var(--colorTheme_Violet);}
.StyledKey_ARed             {background: linear-gradient(135deg, var(--colorTheme_-ARed) 0%, var(--colorTheme_-ARed) 50%, var(--colorTheme_-ARedLigth) 100%); border: 2px solid var(--colorTheme_-ARed); color: #FFF; text-shadow: 0 0 16px #FFF; font-weight: 700; box-shadow: 0 0 3px var(--colorTheme_-ARed);}
.StyledKeyOut_ARed          {color: var(--colorTheme_-ARed); font-weight: 700; box-shadow: 0 0 3px var(--colorTheme_-ARed); border: 2px solid var(--colorTheme_-ARed);}
.StyledKey_Siren            {background: linear-gradient(160deg, var(--colorTheme_Siren) 0%, var(--colorTheme_Siren) 50%, var(--colorTheme_SirenLigth) 100%); border: 2px solid var(--colorTheme_Siren); color: #FFF; text-shadow: 0 0 16px #FFF; font-weight: 700; box-shadow: 0 0 3px var(--colorTheme_Siren);}
.StyledKeyOut_Siren         {color: var(--colorTheme_Siren); font-weight: 700; box-shadow: 0 0 3px var(--colorTheme_Siren); border: 2px solid var(--colorTheme_Siren);}
.StyledKey_Sky              {background: linear-gradient(160deg, var(--colorTheme_Sky) 0%, var(--colorTheme_SkyLigth) 50%, var(--colorTheme_Sky) 100%); border: 2px solid var(--colorTheme_Sky); color: #000; text-shadow: 0 0 16px #FFF; font-weight: 700; box-shadow: 0 0 3px var(--colorTheme_Sky);}
.StyledKeyOut_Sky           {color: var(--colorTheme_Sky); font-weight: 700; box-shadow: 0 0 3px var(--colorTheme_Sky); border: 2px solid var(--colorTheme_Sky);}
.StyledKey_Grey             {background: linear-gradient(180deg, var(--colorTheme_Grey) 0%, var(--colorTheme_Grey) 50%, var(--colorTheme_GreyLigth) 100%); border: 2px solid var(--colorTheme_Grey); color: #FFF; text-shadow: 0 0 16px #FFF; font-weight: 700; box-shadow: 0 0 3px var(--colorTheme_Grey);}
.StyledKeyOut_Grey          {color: var(--colorTheme_Grey); font-weight: 700; box-shadow: 0 0 3px var(--colorTheme_Grey); border: 2px solid var(--colorTheme_Grey);}
.StyledKey_KL               {background: linear-gradient(090deg, var(--color-KL_Dark) 0%, var(--color-KL_Dark) 50%, var(--color-KL) 100%); border: 2px solid var(--color-KL_Dark); color: #FFF; text-shadow: 0 0 16px #FFF; font-weight: 700; box-shadow: 0 0 3px var(--color-KL_Dark);}
.StyledKeyOut_KL            {color: var(--color-KL_Dark); font-weight: 700; box-shadow: 0 0 3px var(--color-KL_Dark); border: 2px solid var(--color-KL_Dark);}
.StyledKey_ITH              {background: linear-gradient(090deg, var(--color-ITH_OrangeLight) 0%, var(--color-ITH_OrangeLight) 100%); border: 2px solid var(--color-ITH_OrangeLight); color: #000; text-shadow: 0 0 16px #FFF; font-weight: 700; box-shadow: 0 0 3px rgba(0,0,0,0.6);}
.StyledKeyOut_ITH           {color: var(--color-ITH_Pink); font-weight: 700; box-shadow: 0 0 3px var(--color-ITH_Pink); border: 2px solid var(--color-ITH_Pink);}
.StyledKey_Sky:hover, .StyledKey_Siren:hover, .StyledKey_Magic:hover, .StyledKey_Violet:hover, .StyledKey_ARed:hover, .StyledKey_Grey:hover, .StyledKey_KL:hover, .StyledKey_ITH:hover                      {opacity: 0.7;}
.StyledKeyOut_Sky:hover, .StyledKeyOut_Siren:hover, .StyledKeyOut_Magic:hover, .StyledKeyOut_Violet:hover, .StyledKeyOut_ARed:hover, .StyledKeyOut_Grey:hover, .StyledKeyOut_KL:hover, .StyledKeyOut_ITH:hover    {filter: contrast(0%);}
.keyControl, .keyConnect, .keyControl:hover, .keyConnect:hover { transition: background 200ms; }
.keyControl, .keyConnect                            {padding: 8px 16px; display: block; text-align: center; }
.keyControl:hover, .keyConnect:hover                {cursor: pointer;}

/* partner-logo */
.brand-logo-kaspersky, .brand-logo-kaspersky, .brand-logo-kaspersky { background-repeat: no-repeat; }
.brand-logo-kaspersky       { width: 188px; height: 36px; margin: 10px 0 10px 0; background-image: url(../images/logo-kaspersky.png); }
.brand-logo-drweb           { width: 188px; height: 33px; margin: 11px 0 12px 0; background-image: url(../images/logo-drweb.png); }
.brand-logo-myitmaster      { width: 096px; height: 33px; margin: 10px 0 10px 0; background-image: url(../images/logo-myitmaster.jpg); }
.brand-logo-ithelper        { width: 116px; height: 33px; margin: 10px 0 10px 0; background-image: url(../images/logo-ithelper.png); background-repeat: no-repeat; }

/* gradient (+Partners) */
.StyledHead_KL              {background: linear-gradient(90deg, var(--color-KL) 0%, var(--color-KL_Light) 100%);}
.StyledHead_KL_GreenLight   {background: linear-gradient(90deg, var(--color-KL) 0%, var(--color-KL_GreenLight) 100%);}
.StyledHead_KL_Standard     {background: linear-gradient(90deg, var(--color-KL_Standard) 0%, var(--color-KL_Standard) 100%);}
.StyledHead_KL_Plus         {background: linear-gradient(90deg, var(--color-KL_Plus) 0%, var(--color-KL_Plus) 100%);}
.StyledHead_KL_Premium      {background: linear-gradient(90deg, var(--color-KL_Premium) 0%, var(--color-KL_Premium) 100%);}
.StyledHead_DW              {background: linear-gradient(90deg, var(--color-DW) 0%, var(--color-DW) 100%);}
.StyledHead_ITH             {background: linear-gradient(90deg, var(--color-ITH_Pink) 0%, var(--color-ITH_Orange) 100%);}

/* ext-props */
.icon-icons-01              { width: 20px; height: 20px; background-image: url(../images/icon-01.svg); filter: invert(0%); }
.icon-icons-02              { width: 20px; height: 20px; background-image: url(../images/icon-02.svg); filter: invert(0%); }
.icon-icons-03              { width: 20px; height: 20px; background-image: url(../images/icon-03.svg); filter: invert(0%); }
.icon-icons-04              { width: 20px; height: 20px; background-image: url(../images/icon-04.svg); filter: invert(0%); }
.icon-icons-05              { width: 20px; height: 20px; background-image: url(../images/icon-05.svg); filter: invert(0%); }
.icon-icons-06              { width: 20px; height: 20px; background-image: url(../images/icon-06.svg); filter: invert(0%); }
.icon-icons-07              { width: 20px; height: 20px; background-image: url(../images/icon-07.svg); filter: invert(0%); }
.icon-icons-08              { width: 20px; height: 20px; background-image: url(../images/icon-08.svg); filter: invert(0%); }
.icon-icons-09              { width: 20px; height: 20px; background-image: url(../images/icon-09.svg); filter: invert(0%); }
.icon-icons-10              { width: 20px; height: 20px; background-image: url(../images/icon-10.svg); filter: invert(0%); }
.icon-icons-11              { width: 20px; height: 20px; background-image: url(../images/icon-11.svg); filter: invert(0%); }
.icon-icons-12              { width: 20px; height: 20px; background-image: url(../images/icon-12.svg); filter: invert(0%); }
.icon-icons-13              { width: 20px; height: 20px; background-image: url(../images/icon-13.svg); filter: invert(0%); }
.icon-icons-14              { width: 20px; height: 20px; background-image: url(../images/icon-14.svg); filter: invert(0%); }
.icon-icons-15              { width: 20px; height: 20px; background-image: url(../images/icon-15.svg); filter: invert(0%); }
.icon-icons-16              { width: 20px; height: 20px; background-image: url(../images/icon-16.svg); filter: invert(0%); }
.icon-icons-17              { width: 20px; height: 20px; background-image: url(../images/icon-17.svg); filter: invert(0%); }
.icon-icons-18              { width: 20px; height: 20px; background-image: url(../images/icon-18.svg); filter: invert(0%); }
.icon-icons-19              { width: 20px; height: 20px; background-image: url(../images/icon-19.svg); filter: invert(0%); transform: scale(1, -1); }
.icon-icons-20              { width: 20px; height: 20px; background-image: url(../images/icon-20.svg); filter: invert(0%); transform: rotate(180deg); }
.icon-icons-21              { width: 20px; height: 20px; background-image: url(../images/icon-21.svg); filter: invert(0%); }
.icon-icons-22              { width: 20px; height: 20px; background-image: url(../images/icon-22.svg); filter: invert(0%); }
.icon-icons-23              { width: 20px; height: 20px; background-image: url(../images/icon-23.svg); filter: invert(0%); transform: scale(-1, -1); }
.icon-icons-24              { width: 20px; height: 20px; background-image: url(../images/icon-24.svg); filter: invert(0%); transform: rotate(180deg); }
.icon-icons-25              { width: 20px; height: 20px; background-image: url(../images/icon-25.svg); filter: invert(0%); transform: rotate(180deg); }
.icon-icons-26              { width: 20px; height: 20px; background-image: url(../images/icon-26.svg); filter: invert(0%); transform: rotate(180deg); }

.icon-icons-28              { width: 20px; height: 20px; background-image: url(../images/icon-28.svg); filter: invert(0%); transform: scale(1, -1); }
.icon-icons-29              { width: 20px; height: 20px; background-image: url(../images/icon-29.svg); filter: invert(0%); transform: rotate(180deg); }
.icon-icons-30              { width: 20px; height: 20px; background-image: url(../images/icon-30.svg); filter: invert(0%); transform: scale(1, -1); }
.icon-icons-31              { width: 20px; height: 20px; background-image: url(../images/icon-31.svg); filter: invert(0%); }
.icon-icons-32              { width: 20px; height: 20px; background-image: url(../images/icon-32.svg); filter: invert(0%); }
.icon-icons-33              { width: 20px; height: 20px; background-image: url(../images/icon-33.svg); filter: invert(0%); transform: scale(1, -1); }
.icon-icons-34              { width: 20px; height: 20px; background-image: url(../images/icon-34.svg); filter: invert(0%); }
.icon-icons-35              { width: 20px; height: 20px; background-image: url(../images/icon-35.svg); filter: invert(0%); transform: scale(1, -1); }
.icon-icons-36              { width: 20px; height: 20px; background-image: url(../images/icon-36.svg); filter: invert(0%); }
.icon-icons-37              { width: 20px; height: 20px; background-image: url(../images/icon-37.svg); filter: invert(0%); }
.icon-icons-38              { width: 19px; height: 20px; background-image: url(../images/icon-38.svg); filter: invert(0%); transform: scale(-1, -1); }
.icon-icons-39              { width: 19px; height: 20px; background-image: url(../images/icon-39.svg); filter: invert(0%); transform: scale(1, -1); }
.icon-icons-40              { width: 19px; height: 20px; background-image: url(../images/icon-40.svg); filter: invert(0%); transform: scale(1, -1); }
.icon-icons-41              { width: 19px; height: 20px; background-image: url(../images/icon-41.svg); filter: invert(0%); transform: scale(1, -1); }
.icon-icons-42              { width: 20px; height: 20px; background-image: url(../images/icon-42.svg); filter: invert(0%); }
.icon-icons-43              { width: 19px; height: 20px; background-image: url(../images/icon-43.svg); filter: invert(0%); transform: scale(1, -1); }
.icon-icons-44              { width: 20px; height: 20px; background-image: url(../images/icon-44.svg); filter: invert(0%); transform: scale(1, -1); }
.icon-icons-45              { width: 20px; height: 20px; background-image: url(../images/icon-45.svg); filter: invert(0%); transform: scale(1, -1); }
.icon-icons-46              { width: 20px; height: 20px; background-image: url(../images/icon-46.svg); filter: invert(0%); transform: scale(-1, -1);}
.icon-icons-47              { width: 20px; height: 20px; background-image: url(../images/icon-47.svg); filter: invert(0%); transform: scale(1, -1); }
.icon-icons-48              { width: 20px; height: 20px; background-image: url(../images/icon-48.svg); filter: invert(0%); transform: scale(1, -1); }
.icon-icons-49              { width: 20px; height: 20px; background-image: url(../images/icon-49.svg); filter: invert(0%); transform: scale(1, -1); }
.icon-icons-50              { width: 20px; height: 20px; background-image: url(../images/icon-50.svg); filter: invert(0%); transform: scale(1, -1); }
.icon-icons-51              { width: 20px; height: 20px; background-image: url(../images/icon-51.svg); filter: invert(0%); transform: scale(1, -1); }
.icon-icons-52              { width: 20px; height: 20px; background-image: url(../images/icon-52.svg); filter: invert(0%); transform: scale(1, -1); }
.icon-icons-53              { width: 20px; height: 20px; background-image: url(../images/icon-53.svg); filter: invert(0%); transform: scale(1, -1); }
.icon-icons-54              { width: 20px; height: 20px; background-image: url(../images/icon-54.svg); filter: invert(0%); transform: scale(1, -1); }
.icon-icons-55              { width: 20px; height: 20px; background-image: url(../images/icon-55.svg); filter: invert(0%); transform: scale(1, -1); }
.icon-icons-56              { width: 20px; height: 20px; background-image: url(../images/icon-56.svg); filter: invert(0%); transform: scale(1, -1); }
.icon-icons-57              { width: 20px; height: 20px; background-image: url(../images/icon-57.svg); filter: invert(0%); transform: scale(1, -1); }
.icon-icons-58              { width: 20px; height: 20px; background-image: url(../images/icon-58.svg); filter: invert(0%); transform: scale(1, -1); }
.icon-icons-59              { width: 20px; height: 20px; background-image: url(../images/icon-59.svg); filter: invert(0%); transform: scale(1, -1); }
.icon-icons-60              { width: 20px; height: 20px; background-image: url(../images/icon-60.svg); filter: invert(0%); transform: scale(1, 1); }
.icon-icons-61              { width: 20px; height: 20px; background-image: url(../images/icon-61.svg); filter: invert(0%); transform: scale(1, 1); }
.icon-icons-62              { width: 20px; height: 20px; background-image: url(../images/icon-62.svg); filter: invert(0%); transform: scale(1, 1); }

/* auto height vertical (main → div → x*div) */
.autoHeight                                 {display: flex;}
.autoHeight > div                           {display: flex; flex-direction: column; width: 100%;}
.autoHeight > div > div:nth-child(1)        {flex: 0 1 32px;}
.autoHeight > div > div:nth-child(2)        {flex: 1 1 auto;}
.autoHeight > div > div:nth-child(2) > div  {height: auto;}
.autoHeight > div > div:nth-child(3)        {flex: 0 1 32px;}

/* page: frame → products → products */
.FrameProductsList > div                            {position: relative; background-color: #FFFFFF; margin-top: 0; padding: 16px; box-shadow: 0 0 16px rgba(0,0,0,0.15);}
.FrameProductsList > div > div                      {display: flex;}
.FrameProductsList > div > div > div                {/*align-content: center;*/ justify-content: center;}
.FrameProductsList > div > div > div:nth-child(1)   {width: 150px; text-align: center; margin-right: 16px;}
.FrameProductsList > div > div > div > img          {max-height: 150px; height: 150px; max-width: 150px; width: auto;}
.FrameProductsList > div > div > div:nth-child(2)   {width: calc(100% - 166px);}
.FrameProductsList > div > div > div:nth-child(2) > div:nth-child(1) {display: flex;}
.FrameProductsList > div > div > div:nth-child(2) > div:nth-child(1) > div {display: flex;align-items: center; font-size: 18px; line-height: 135%; font-weight: 700; padding-right: 8px;}
.FrameProductsList > div > div > div:nth-child(2) > div:nth-child(1) > div:nth-child(2) {display: flex;}
.FrameProductsList > div > div > div:nth-child(2) > div:nth-child(3) {display: flex; padding-top: 16px;}
.FrameProductsList > div > div > div:nth-child(2) > div:nth-child(3) > div {line-height: 135%;}
.FrameProductsList > div > div > div:nth-child(2) > div:nth-child(3) > div:nth-child(1) {display: flex; width: calc(100% - 150px);}
.FrameProductsList > div > div > div:nth-child(2) > div:nth-child(3) > div:nth-child(1) > div:nth-child(1) {width: calc((100%)/2); padding-right: 16px; line-height: 135%;}
.FrameProductsList > div > div > div:nth-child(2) > div:nth-child(3) > div:nth-child(1) > div:nth-child(2) {width: calc((100%)/2); }
.FrameProductsList > div > div > div:nth-child(2) > div:nth-child(3) > div:nth-child(2) > div {width: 150px; }
.FrameProductsList > div > div > div:nth-child(2) > div:nth-child(3) > div:nth-child(2) > div,
.FrameProductsList > div > div > div:nth-child(2) > div:nth-child(3) > div:nth-child(2) > div > div,
.FrameProductsList > div > div > div:nth-child(2) > div:nth-child(3) > div:nth-child(2) > div > div > div {text-align: center;}
.FrameProductsList > div > div > div:nth-child(2) > div:nth-child(3) > div:nth-child(2) > div:nth-child(1) > div > div {color: #888888;}
.FrameProductsList > div > div > div:nth-child(2) > div:nth-child(3) > div:nth-child(2) > div:nth-child(1) > div {margin-bottom: 20px;}
.FrameProductsList > div > div > div:nth-child(2) > div:nth-child(3) > div:nth-child(2) > div:nth-child(2) {margin-bottom: 40px;}
.FrameProductsList > div > div > div:nth-child(2) > div:nth-child(3) > div:nth-child(2) > div:nth-child(2) > div {font-size: 21px; line-height: 135%; font-weight: 500;}
@media (max-width: 1300px) {
    .FrameProductsList > div > div > div:nth-child(2) > div:nth-child(3) > div:nth-child(1) > div:nth-child(1) {width: 40%;}
    .FrameProductsList > div > div > div:nth-child(2) > div:nth-child(3) > div:nth-child(1) > div:nth-child(2) {width: 60%;}
    .FrameProductsList > div > div > div:nth-child(2) > div:nth-child(3) {display: block;}
    .FrameProductsList > div > div > div:nth-child(2) > div:nth-child(3) > div:nth-child(2) {margin-top: 40px;}
    .FrameProductsList > div > div > div:nth-child(2) > div:nth-child(3) > div:nth-child(2) > div {}
    .FrameProductsList > div > div > div:nth-child(2) > div:nth-child(3) > div:nth-child(1) {width: 100%;}
    .FrameProductsList > div > div > div:nth-child(2) > div:nth-child(3) > div:nth-child(2) {display: flex; justify-content: right; align-content: center;}
    .FrameProductsList > div > div > div:nth-child(2) > div:nth-child(3) > div:nth-child(2) > div,
    .FrameProductsList > div > div > div:nth-child(2) > div:nth-child(3) > div:nth-child(2) > div > div,
    .FrameProductsList > div > div > div:nth-child(2) > div:nth-child(3) > div:nth-child(2) > div > div > div {justify-content: center; align-self: center;}
    .FrameProductsList > div > div > div:nth-child(2) > div:nth-child(3) > div:nth-child(2) > div:nth-child(1) > div,
    .FrameProductsList > div > div > div:nth-child(2) > div:nth-child(3) > div:nth-child(2) > div:nth-child(2) {margin-bottom: 0;}
}
@media (max-width: 920px) {
    .FrameProductsList > div > div > div:nth-child(2) > div:nth-child(3) > div:nth-child(1) {display: block;}
    .FrameProductsList > div > div > div:nth-child(2) > div:nth-child(3) > div:nth-child(1) > div:nth-child(1) {width: 100%; padding-right: 0;}
    .FrameProductsList > div > div > div:nth-child(2) > div:nth-child(3) > div:nth-child(1) > div:nth-child(2) {width: 100%; padding-right: 0; margin-top: 16px;}
    .FrameProductsList > div > div > div:nth-child(2) > div:nth-child(3) > div:nth-child(2) > div {width: 100%;}

}
@media (max-width: 720px) {
    .FrameProductsList > div > div > div:nth-child(2) > div:nth-child(1) {display: block;}
    .FrameProductsList > div > div > div:nth-child(2) > div:nth-child(1) > div {text-align: center; justify-content: center;}
    .FrameProductsList > div > div > div:nth-child(2) > div:nth-child(1) > div:nth-child(2) {justify-content: center;}
    .FrameProductsList > div > div                      {display: block;}
    .FrameProductsList > div > div > div:nth-child(1)   {width: 100%;}
    .FrameProductsList > div > div > div:nth-child(2)   {width: 100%;}
    .FrameProductsList > div                            {background-color: rgba(255,255,255,1.0); margin-top: 16px; padding: 0; box-shadow: 0 0 16px rgba(0,0,0,0.15);}
    .FrameProductsList > div > div > div:nth-child(2) > div:nth-child(3) > div:nth-child(1) > div:nth-child(1) {margin: 16px 16px 0 16px; width: calc(100% - 32px);}
    .FrameProductsList > div > div > div:nth-child(2) > div:nth-child(3) > div:nth-child(1) > div:nth-child(2) {margin: 16px 16px 0 16px; width: calc(100% - 32px);}
    .FrameProductsList > div > div > div:nth-child(2) > div:nth-child(3) > div:nth-child(2) { margin: 16px 16px 0 16px; width: calc(100% - 32px); padding-bottom: 16px;}
    .FrameProductsList > div > div > div:nth-child(2) > div:nth-child(3) {padding-top: 0;}
    .FrameProductsList > div > div > div > img          { margin: 16px 0; }
    .FrameProductsList > div > div > div:nth-child(2) > div:nth-child(1) > div {/*width: calc(100% - 32px); margin: 0 16px;*/}
    .FrameProductsList > div > div > div:nth-child(2) > div:nth-child(1) > div:nth-child(2) {width: 100%;}
}
@media (max-width: 530px) {
    .FrameProductsList > div > div > div:nth-child(2) > div:nth-child(3) > div:nth-child(2) {display: block; padding-bottom: 0; margin: 16px 0 0 0; width: 100%; border-radius: 0 0 3px 3px;}
    .FrameProductsList > div > div > div:nth-child(2) > div:nth-child(3) > div:nth-child(2) > div:not(:first-child) {margin-top: 16px;}
}

/* page: frame → products → subs */
.FrameProductsList > div > div > div:nth-child(2) > div:nth-child(2) > div:nth-child(1) > div:nth-child(1) {display: flex;}
.FrameProductsList > div > div > div:nth-child(2) > div:nth-child(2) > div:nth-child(1) > div:nth-child(1) > div:nth-child(1) {margin-right: 8px;}
.FrameProductsList > div > div > div:nth-child(2) > div:nth-child(2)                    { display: flex; padding: 0 0 0 0; }
.FrameProductsList > div > div > div:nth-child(2) > div:nth-child(2) > div:nth-child(1) { width: calc((100% - 150px)*0.5); padding-right: 16px; }
.FrameProductsList > div > div > div:nth-child(2) > div:nth-child(2) > div:nth-child(2) { width: calc((100% - 150px)*0.5 + 150px); }
.FrameProductsList > div > div > div:nth-child(2) > div:nth-child(2) > div > div > div > a {border-bottom: 1px solid #000000;}
.FrameProductsList > div > div > div:nth-child(2) > div:nth-child(2) > div > div > div > a:hover {opacity: 0.7;}
.FrameProductsList > div > div > div:nth-child(2) > div:nth-child(2) > div > div,
.FrameProductsList > div > div > div:nth-child(2) > div:nth-child(2) > div > div > div,
.FrameProductsList > div > div > div:nth-child(2) > div:nth-child(2) > div > div > div > div {line-height: 135%;}
@media (max-width: 1300px) {
    .FrameProductsList > div > div > div:nth-child(2) > div:nth-child(2) > div:nth-child(1) { width: 40%; }
    .FrameProductsList > div > div > div:nth-child(2) > div:nth-child(2) > div:nth-child(2) { width: 60%; }
}
@media (max-width: 920px) {
    .FrameProductsList > div > div > div:nth-child(2) > div:nth-child(2)                    { display: block; }
    .FrameProductsList > div > div > div:nth-child(2) > div:nth-child(2) > div:nth-child(1),
    .FrameProductsList > div > div > div:nth-child(2) > div:nth-child(2) > div:nth-child(2) { width: 100%; padding-right: 0; margin-top: 16px; }
}
@media (max-width: 720px) {
    .FrameProductsList > div > div > div:nth-child(2) > div:nth-child(2) { padding: 16px 16px 0 16px;}
}

/* page: frame → products → hit */
.FrameProductsHitList {display: flex; width: 100%;}
.FrameProductsHitList > div {position: relative; flex: 1; background-color: #FFFFFF; margin-top: 0; box-shadow: 0 0 16px rgba(0,0,0,0.15);}
.FrameProductsHitList > div > div:nth-child(1) {height: 5px;opacity: 0.01;}
.FrameProductsHitList > div > div:nth-child(2) > div:nth-child(2) {width: calc(100% - 150px);}
.FrameProductsHitList > div > div:nth-child(2) > div > div:nth-child(1) {text-align: center; margin: 16px 0 0 0; height: 150px;}
.FrameProductsHitList > div > div:nth-child(2) > div > div:nth-child(1) > img {max-height: 150px; height: 150px; max-width: 150px; width: auto;}
.FrameProductsHitList > div > div:nth-child(2) > div > div:nth-child(2) { margin: 16px 0 0 0;}
.FrameProductsHitList > div > div:nth-child(2) > div > div:nth-child(2) > div:nth-child(1) {text-align: center; font-size: 18px; line-height: 135%; font-weight: 700;}
.FrameProductsHitList > div > div:nth-child(2) > div > div:nth-child(2) > div:nth-child(2) {margin: 8px 0 16px 0; display: flex; justify-content: center;}
.FrameProductsHitList > div > div:nth-child(2) > div > div:nth-child(2) > div:nth-child(2) > div:nth-child(1) {align-self: center; padding-right: 8px;}
.FrameProductsHitList > div > div:nth-child(2) > div > div:nth-child(2) > div:nth-child(2) > div:nth-child(2) {display: flex;}
.FrameProductsHitList > div > div:nth-child(2) > div > div:nth-child(2) > div:nth-child(3) {padding: 8px 16px 8px 16px;}
.FrameProductsHitList > div > div:nth-child(2) > div > div:nth-child(2) > div:nth-child(4) > div:nth-child(1) {min-height: 40px; margin-top:16px; }
.FrameProductsHitList > div > div:nth-child(2) > div > div:nth-child(2) > div:nth-child(4) > div:nth-child(1) > div > div > div {text-align:center; color: #888888;}
.FrameProductsHitList > div > div:nth-child(2) > div > div:nth-child(2) > div:nth-child(4) > div:nth-child(2) > div > div > div {font-size: 22px; line-height: 135%; font-weight: 500; text-align: center; padding-top: 16px;}
.FrameProductsHitList > div > div:nth-child(2) > div > div:nth-child(2) > div:nth-child(4) > div:nth-child(3) {padding: 16px;}
@media (max-width: 1060px) {
    .FrameProductsHitList > div > div:nth-child(2) > div > div:nth-child(2) > div:nth-child(1) {min-height: 50px; padding: 0 16px;}
}
@media (max-width: 920px) {
    .FrameProductsHitList {display: block;}
    .FrameProductsHitList > div > div:nth-child(2) > div > div:nth-child(2) > div:nth-child(4) > div:nth-child(3) {padding: 0; margin-top: 16px;}
    .FrameProductsHitList > div > div:nth-child(2) > div > div:nth-child(2) > div:nth-child(4) {display: flex;}
    .FrameProductsHitList > div > div:nth-child(2) > div > div:nth-child(2) > div:nth-child(4) > div {flex: 1;}
    .FrameProductsHitList > div > div:nth-child(1) {height: 5px;}
    .FrameProductsHitList > div > div:nth-child(2) > div > div:nth-child(1) {width: 150px; padding-right: 16px; margin: 16px 0 16px 16px;}
    .FrameProductsHitList > div > div:nth-child(2) > div {display: flex;}
    .FrameProductsHitList > div > div:nth-child(2) > div > div:nth-child(2) {width: calc(100% - 150px); margin: 16px 16px 16px 0;}
    .FrameProductsHitList > div > div:nth-child(2) > div > div:nth-child(2) > div:nth-child(1) {text-align: left;}
    .FrameProductsHitList > div > div:nth-child(2) > div > div:nth-child(2) > div:nth-child(2) {justify-content: left; margin: 4px 0 0 0;}
}
@media (max-width: 720px) {
    .FrameProductsHitList > div > div:nth-child(2) > div > div:nth-child(1) {text-align: center; width: auto;}
    .FrameProductsHitList > div > div:nth-child(2) > div > div:nth-child(2) > div:nth-child(1) {text-align: center;}
    .FrameProductsHitList > div > div:nth-child(2) > div > div:nth-child(2) > div:nth-child(2) {justify-content: center;}
    .FrameProductsHitList > div > div:nth-child(2) > div > div:nth-child(2) {width: 100%; margin: 16px 16px 0 0;}
    .FrameProductsHitList > div > div:nth-child(1) {height: 5px;}
    .FrameProductsHitList > div > div:nth-child(2) > div {display: block; padding: 0 16px 16px 16px;}
}
@media (max-width: 530px) {
    .FrameProductsHitList > div > div:nth-child(2) > div > div:nth-child(2) > div:nth-child(4) {display: block;}
    .FrameProductsHitList > div > div:nth-child(1) {height: 5px;}
    .FrameProductsHitList > div > div:nth-child(2) > div {display: block; padding: 0 0 0 0;}
}

/* page: bo → body */
.UniBOBody                                              { width: 100%; min-height: 600px; border-top: 1px dashed rgba(69,85,96,0.5); border-bottom: 1px dashed rgba(69,85,96,0.5); padding: 20px 0 60px 0; }
.UniBOBody__titleBlock                                  { font-size: 22px; padding-bottom: 16px;  color: rgba(69,85,96,0.85); font-weight: 700; }
.UniBOBody__brakeBlock                                  { padding-top: 32px; }
.UniBOBody__titleBlock-linkInUrl                        {display: inline; font-size: 22px;}
.UniBOBody__titleBlock-linkInUrl > div                  {display: none; position: absolute; z-index: 2; width: 300px; background-color: #FFFFFF; padding: 10px; border: 2px solid #666666; border-radius: 3px; left: 830px;}
.UniBOBody__titleBlock-linkInUrl:hover                  {cursor: pointer;}
.UniBOBody__titleBlock-linkInUrl:hover > div            {display: block;}
.UniBO > a, .UniBO > div > a, .UniBO > div > div > a    { display: inline-block; text-decoration: none; font-weight: 600; }
.UniBOSiren > a, .UniBOSiren > div > a, .UniBOSiren > div > div > a { color: var(--colorTheme_Siren); }
.UniBOMagic > a, .UniBOMagic > div > a, .UniBOMagic > div > div > a { color: var(--colorTheme_Magic); }
.UniBOARed > a, .UniBOARed > div > a, .UniBOARed > div > div > a { color: var(--colorTheme_-ARed); }
.UniBOViolet > a, .UniBOViolet > div > a, .UniBOViolet > div > div > a { color: var(--colorTheme_Violet); }
.UniBOSky > a, .UniBOSky > div > a, .UniBOSky > div > div > a { color: var(--colorTheme_Sky); }
.UniBO > a:hover, .UniBO > div > a:hover, .UniBO > div > div > a:hover { text-decoration: underline; }
.UniBO__minWidthKey                                     { min-width: 120px; }
.UniBO__textCenter                                      {text-align: center;}
.UniBOBody__mainBlock > div                             { display: inline-block; }
.UniBOBody__leftBlock                                   { width: 250px; background: linear-gradient(0deg, rgba(000,000,0,0.05) 0%, rgba(000,000,0,0.01) 100%); margin-right: 20px; padding: 10px; border-radius: 10px; -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3); moz-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3); box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);}
.UniBOBody__leftBlock:hover                             { background: linear-gradient(0deg, rgba(000,000,0,0.02) 0%, rgba(000,000,0,0.04) 100%); }
.UniBOBody__rightBlock                                  { width: calc(100% - 290px); padding-top: 10px; padding-bottom: 150px;}
.UniBOBody__disabled                                    {opacity: 0.5;}

/* page: bo → button */
.UniBO__mainButton, .UniBO__mainButton:hover, .UniBO__mainButtonInvert, .UniBO__mainButtonInvert:hover {font-weight:700; border-radius: 3px; padding: 4px; width: calc(100% - 12px); text-align: center; cursor: pointer;}
.UniBO__mainButtonSiren, .UniBO__mainButtonSiren:hover, .UniBO__mainButtonInvertSiren, .UniBO__mainButtonInvertSiren:hover {border: 2px solid var(--colorTheme_Siren);}
.UniBO__mainButtonMagic, .UniBO__mainButtonMagic:hover, .UniBO__mainButtonInvertMagic, .UniBO__mainButtonInvertMagic:hover {border: 2px solid var(--colorTheme_Magic);}
.UniBO__mainButtonARed, .UniBO__mainButtonARed:hover, .UniBO__mainButtonInvertARed, .UniBO__mainButtonInvertARed:hover {border: 2px solid var(--colorTheme_-ARed);}
.UniBO__mainButtonViolet, .UniBO__mainButtonViolet:hover, .UniBO__mainButtonInvertViolet, .UniBO__mainButtonInvertViolet:hover {border: 2px solid var(--colorTheme_Violet);}
.UniBO__mainButtonSky, .UniBO__mainButtonSky:hover, .UniBO__mainButtonInvertSky, .UniBO__mainButtonInvertSky:hover {border: 2px solid var(--colorTheme_Sky);}
.UniBO__mainButtonSiren, .UniBO__mainButtonInvertSiren:hover { color: #FFF; background-color: var(--colorTheme_Siren);}
.UniBO__mainButtonInvertSiren, .UniBO__mainButtonSiren:hover { background-color: #FFF; color: var(--colorTheme_Siren);}
.UniBO__mainButtonMagic, .UniBO__mainButtonInvertMagic:hover { color: #FFF; background-color: var(--colorTheme_Magic);}
.UniBO__mainButtonInvertMagic, .UniBO__mainButtonMagic:hover { background-color: #FFF; color: var(--colorTheme_Magic);}
.UniBO__mainButtonARed, .UniBO__mainButtonInvertARed:hover  { color: #FFF; background-color: var(--colorTheme_-ARed);}
.UniBO__mainButtonInvertARed, .UniBO__mainButtonARed:hover  { background-color: #FFF; color: var(--colorTheme_-ARed);}
.UniBO__mainButtonViolet, .UniBO__mainButtonInvertViolet:hover{ color: #FFF; background-color: var(--colorTheme_Violet);}
.UniBO__mainButtonInvertViolet, .UniBO__mainButtonViolet:hover{ background-color: #FFF; color: var(--colorTheme_Violet);}
.UniBO__mainButtonSky, .UniBO__mainButtonInvertSky:hover   { color: #FFF; background-color: var(--colorTheme_Sky);}
.UniBO__mainButtonInvertSky, .UniBO__mainButtonSky:hover   { background-color: #FFF; color: var(--colorTheme_Sky);}
.UniBO__mainButton, .UniBO__mainButtonInvert             {transition: 0.5s;}
.UniBO__mainButton:hover, .UniBO__mainButtonInvert:hover {transition: 0.1s;}
.UniBO__mainButton-disable                               {opacity: 0.5; filter: grayscale(1); }
.UniBO__mainButton-disable:nth-child(2)                  {margin-top: 8px;}

/* page: bo → shadow */
.shadowLightSiren                                        {-moz-box-shadow: 0 0 3px var(--colorTheme_Siren);-webkit-box-shadow: 0 0 3px var(--colorTheme_Siren);box-shadow: 0 0 3px var(--colorTheme_Siren);}
.shadowLightMagic                                        {-moz-box-shadow: 0 0 3px var(--colorTheme_Magic);-webkit-box-shadow: 0 0 3px var(--colorTheme_Magic);box-shadow: 0 0 3px var(--colorTheme_Magic);}
.shadowLightARed                                         {-moz-box-shadow: 0 0 3px var(--colorTheme_-ARed);-webkit-box-shadow: 0 0 3px var(--colorTheme_-ARed);box-shadow: 0 0 3px var(--colorTheme_-ARed);}
.shadowLightViolet                                       {-moz-box-shadow: 0 0 3px var(--colorTheme_Violet);-webkit-box-shadow: 0 0 3px var(--colorTheme_Violet);box-shadow: 0 0 3px var(--colorTheme_Violet);}
.shadowLightSky                                          {-moz-box-shadow: 0 0 3px var(--colorTheme_Sky);-webkit-box-shadow: 0 0 3px var(--colorTheme_Sky);box-shadow: 0 0 3px var(--colorTheme_Sky);}
.shadowLightSiren:hover                                  {-moz-box-shadow: 0 0 7px var(--colorTheme_Siren);-webkit-box-shadow: 0 0 7px var(--colorTheme_Siren);box-shadow: 0 0 7px var(--colorTheme_Siren);}
.shadowLightMagic:hover                                  {-moz-box-shadow: 0 0 7px var(--colorTheme_Magic);-webkit-box-shadow: 0 0 7px var(--colorTheme_Magic);box-shadow: 0 0 7px var(--colorTheme_Magic);}
.shadowLightARed:hover                                   {-moz-box-shadow: 0 0 7px var(--colorTheme_-ARed);-webkit-box-shadow: 0 0 7px var(--colorTheme_-ARed);box-shadow: 0 0 7px var(--colorTheme_-ARed);}
.shadowLightViolet:hover                                 {-moz-box-shadow: 0 0 7px var(--colorTheme_Violet);-webkit-box-shadow: 0 0 7px var(--colorTheme_Violet);box-shadow: 0 0 7px var(--colorTheme_Violet);}
.shadowLightSky:hover                                    {-moz-box-shadow: 0 0 7px var(--colorTheme_Sky);-webkit-box-shadow: 0 0 7px var(--colorTheme_Sky);box-shadow: 0 0 7px var(--colorTheme_Sky);}

/* page: bo → loginForm */
.UniBOBody__loginForm                       { width: 230px; height: 500px; margin: 0 auto; }
.UniBOBody__loginForm-login, .UniBOBody__loginForm-password, .UniBOBody__loginForm-button { padding: 0 0 5px 0; }
.UniBOBody__loginForm-login                 { padding-top: 150px; }
.UniBOBody__loginForm-login > input, .UniBOBody__loginForm-password > input { border: 2px solid rgba(69,85,96,0.30); border-radius: 3px; padding: 7px 3px; margin-bottom: 3px; width: calc(100% - 10px); }
.UniBOBody__loginForm-err                   { padding-top:20px; font-size: 12px; }

/* page: bo → searchForm */
.UniBOBody__searchForm > div {margin-right: 0;}
.UniBOBody__searchForm-dog > input, .popupContent > div > input                             { border: 2px solid rgba(69,85,96,0.30); border-radius: 3px; padding: 4px 3px; margin: 0 1px 3px 1px; width: calc(100% - 12px); }
.UniBOBody__searchForm-dog > div > select, .UniBOBody__searchFormReport > div {width: 100%; max-width: 100%;}
.UniBOBody__searchForm-dog > div > select {border: 2px solid rgba(69,85,96,0.30); border-radius: 3px; padding: 4px 3px; }
.UniBOBody__selectOption-disabled {color: #CCCCCC;}
.UniBOBody__drawReportTable > thead > tr > td {font-weight: 700;}
.UniBOBody__drawReportTable > tbody > tr > td {border-top: 1px dashed rgba(69,85,96,0.5);}
.UniBOBody__drawReportTable > thead > tr > td, .UniBOBody__drawReportTable > tbody > tr > td {padding: 5px 10px 5px 10px;}
.UniBOBody__drawReportTable > thead > tr, .UniBOBody__drawReportTable > tbody > tr {transition: 0.9s;}
.UniBOBody__drawReportTable > thead > tr:hover, .UniBOBody__drawReportTable > tbody > tr:hover {background-color: rgba(69,85,96,0.15);transition: 0.1s;}

/* page: bo → popup */
.popupBack                                  {z-index: 2;background: rgba(0,0,0,0.15); width: 100%; height: 100%;position: fixed;}
.popup                                      {z-index: 4;position: fixed;}
.popupContent > div > input                 {margin-top: 5px;}
.popupContentFull                           {width: 100%;height: 100%;}
.popupContentFull > .popupContent > div     {padding: 10px;}
.popupContent                               {display:block;position: fixed;width:320px;margin-top: 10px;margin-left: calc(50% - 160px - 1px);border: 1px solid rgba(0,0,0,0.3);background-color: #FFFFFF;font-weight: 500;}
.popupShadow                                {-webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3); moz-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3); box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);}
.UniBOHeader__popupKeys                     {text-align: center;}
.UniBOHeader__popupKeys > div               {display: inline-block; text-align: center;margin: 10px; width: 100px;}
.UniBOHeader__popupKeys > div:hover         {width: 100px;}
.UniBOHeader__popupHr                       {width:100%; border-top: 1px solid rgba(0,0,0,0.15);}
.UniBOHeader__title                         {text-align: center; font-size: 18px;}
#popupUserDataEmail, #popupUserDataPhone    {width: calc(100% - 10px); }
.popupHistoryOperations > table             {padding: 10px;}
.popupHistoryOperations > div:nth-child(2)  {max-height: 200px; overflow-y: scroll;}
.popupHistoryOperations > div:nth-child(1)  {padding: 10px !important; }
.popupHistoryOperationsSiren                {border:1px solid var(--colorTheme_SirenShad);}
.popupHistoryOperationsMagic                {border:1px solid var(--colorTheme_MagicShad);}
.popupHistoryOperationsARed                 {border:1px solid var(--colorTheme_-ARedShad);}
.popupHistoryOperationsViolet               {border:1px solid var(--colorTheme_VioletShad);}
.popupHistoryOperationsSky                  {border:1px solid var(--colorTheme_SkyShad);}
.popupHistoryOperations > div               {font-weight: 700; padding: 10px;}
.popupHistoryOperationsSiren > div          {background: linear-gradient(0deg, var(--colorTheme_SirenLigth) 0%, var(--colorTheme_SirenShad) 100%); border-bottom: 1px solid var(--colorTheme_SirenLigth);}
.popupHistoryOperationsMagic > div          {background: linear-gradient(0deg, var(--colorTheme_MagicLigth) 0%, var(--colorTheme_MagicShad) 100%); border-bottom: 1px solid var(--colorTheme_MagicLigth);}
.popupHistoryOperationsARed > div           {background: linear-gradient(0deg, var(--colorTheme_-ARedLigth) 0%, var(--colorTheme_-ARedShad) 100%); border-bottom: 1px solid var(--colorTheme_-ARedLigth);}
.popupHistoryOperationsViolet > div         {background: linear-gradient(0deg, var(--colorTheme_VioletLigth) 0%, var(--colorTheme_VioletShad) 100%); border-bottom: 1px solid var(--colorTheme_VioletLigth);}
.popupHistoryOperationsSky > div            {background: linear-gradient(0deg, var(--colorTheme_SkyLigth) 0%, var(--colorTheme_SkyShad) 100%); border-bottom: 1px solid var(--colorTheme_SkyLigth);}

/* page: bo → radio */
.UniBO__mainRemoveSubs                      {padding-top: 20px; word-wrap:break-word;}
.UniBO__mainRemoveSubs > label              {display: block; }
.UniBO__mainRemoveSubs > label > input, .UniBO__mainRemoveSubs > label > div {display: inline-block; font-weight: 400; font-size: 12px; line-height: 14px; max-width: 90%;}
.UniBO__mainRemoveSubs > div > textarea     {width: calc(100% - 10px); min-height: 50px; padding: 5px; font-size: 12px; line-height: 14px; border: 1px solid rgba(69,85,96,0.3);}
.UniBO__mainRemoveSubs-alert                {text-align: center;}

/* page: bo → Table Hist */
.UniBO__HistTable                           { background-color: #ffffff; width: 100%; }
.UniBO__HistTable > thead > tr > td         { font-weight: 700; padding: 7px 3px; background-color: #ffffff; max-width: 140px; word-break: break-word; }
.UniBO__HistTable > tbody > tr > td         { font-weight: 400; padding: 7px 3px; border-top: 1px dashed rgba(69,85,96,0.5); background-color: #ffffff; word-break: break-word; }
.histNoData                                 {padding: 10px; border: 1px solid red; text-align: center; }

/* page: bo → error and not found */
.notFound                                   { font-size:360px; line-height: 130%; text-align: center; margin-top:100px; color: rgba(128, 128, 128, 0.99); }
.UniBOFooter__errorMessage-head             { font-weight: 700;  }
.UniBOFooter__errorMessage, .UniBOFooter__errorMessage > div {text-align: center; padding-top: 10px;}
.UniBOFooter__errorMessage-symbol           {display:inline-block; border-radius: 50px; font-size:40px; line-height: 40px; width: 60px; height: 50px; font-weight: 700;}
.UniBOFooter__errorMessage-symbolSiren      {border: 3px solid var(--colorTheme_Siren); background-color: var(--colorTheme_SirenShad); color: var(--colorTheme_Siren);}
.UniBOFooter__errorMessage-symbolMagic      {border: 3px solid var(--colorTheme_Magic); background-color: var(--colorTheme_MagicShad); color: var(--colorTheme_Magic);}
.UniBOFooter__errorMessage-symbolARed       {border: 3px solid var(--colorTheme_-ARed); background-color: var(--colorTheme_-ARedShad); color: var(--colorTheme_-ARed);}
.UniBOFooter__errorMessage-symbolViolet     {border: 3px solid var(--colorTheme_Violet); background-color: var(--colorTheme_VioletShad); color: var(--colorTheme_Violet);}
.UniBOFooter__errorMessage-symbolSky        {border: 3px solid var(--colorTheme_Sky); background-color: var(--colorTheme_SkyShad); color: var(--colorTheme_Sky);}

/* page: bo → footer */
.UniBOFooter                                {padding-top: 16px;}
.UniBOFooter > div                          {display: inline-block; color: rgba(69,85,96,0.85); font-weight: 600;}
.UniBOFooter__support                       {float: right;}

/* page: bo → dataUser */
.UniBOBody__userData > div > div                        {display: inline-block; width: 80px; border-bottom: 1px dashed rgba(69,85,96,0.5); padding-bottom:5px; margin-bottom: 10px; overflow: hidden; }
.UniBOBody__userData > div > div:nth-child(2)           {width: calc(100% - 80px)}
.UniBOBody__userTableData > table                       {width: 100%;}
.UniBOBody__userTableData > table > thead > tr > td     {padding-bottom: 10px; font-weight: 700;}
.UniBOBody__userTableData > table > tbody > tr > td     {padding-bottom: 10px; border-top: 1px dashed rgba(69,85,96,0.5);}
.UniBOBody__userTableData > table > thead > tr > td:first-child, .UniBOBody__userTableData > table > tbody > tr > td:first-child {width: 90%; vertical-align: middle;}
.UniBOBody__userTableData > table > thead > tr > td, .UniBOBody__userTableData > table > tbody > tr > td {padding: 5px 10px 5px 10px;}
.UniBOBody__userTableData > table > thead > tr, .UniBOBody__userTableData > table > tbody > tr {transition: 0.9s;}
.UniBOBody__userTableData > table > thead > tr:hover, .UniBOBody__userTableData > table > tbody > tr:hover {background-color: rgba(69,85,96,0.15);transition: 0.1s;}
.UniBOBody__userTableData-afterCoverBlock {display: inline-block; max-width: calc(100% - 34px);}
.UniBOBody__userTableData-coverBlock                    {display: inline-block;}
.UniBOBody__userTableData-cover                         { max-height: 24px; max-width: 18px; padding-right: 0; margin-right: 16px; }
.UniBOBody__userTableData-coverBig                      { display: none; }
.UniBOBody__userTableData-cover:hover + .UniBOBody__userTableData-coverBig {position: absolute; display: block; height: 240px; margin-left: 36px; margin-top: -135px; }
.UniBOBody__userTableData-historyOperationRelative      {z-index: 3; display:none; border:0; position: absolute; background-color: #FFF; padding: 0; border-radius: 3px; margin-left: -100px; width: 680px; }
.UniBOBody__userTableData-historyOperation              {text-decoration: underline; font-weight: 600; cursor: pointer; }
.UniBOBody__userTableData-historyOperationSiren         {color: var(--colorTheme_Siren);}
.UniBOBody__userTableData-historyOperationMagic         {color: var(--colorTheme_Magic);}
.UniBOBody__userTableData-historyOperationARed          {color: var(--colorTheme_-ARed);}
.UniBOBody__userTableData-historyOperationViolet        {color: var(--colorTheme_Violet);}
.UniBOBody__userTableData-historyOperationSky           {color: var(--colorTheme_Sky);}

/* page: bo → header */
.UniBO , .UniBOSiren, .UniBOMagic, .UniBOARed, .UniBOViolet, .UniBOSky {min-width: 1000px; max-width: 1500px; margin: 0 auto; padding: 20px;}
.UniBOHeader                                            {padding-bottom:0; margin-bottom:20px;}
.UniBOHeader__logo                                      {background-repeat:no-repeat;}
.UniBOHeader__logo-shift                                {padding-left: 290px; min-width: 500px;}
.UniBOHeader > div                                      {display: inline-block;}
.UniBOHeader__mainText                                  {font-size: 24px !important; line-height: 24px; padding-bottom: 5px; font-weight: 700; color: rgba(69,85,96,0.85);}
.UniBOHeader__authData                                  {float: right; min-width: 100px; max-width: 200px; overflow: hidden; padding-right: 10px;}
.UniBOHeader__authData-logoPass                         {font-weight: 600; color: rgba(69,85,96,0.85); font-size: 14px;}
.UniBOHeader__authData-exit                             {font-weight: 600;}
.UniBOHeader__authData-exitSiren                        {color: var(--colorTheme_Siren);}
.UniBOHeader__authData-exitMagic                        {color: var(--colorTheme_Magic);}
.UniBOHeader__authData-exitARed                         {color: var(--colorTheme_-ARed);}
.UniBOHeader__authData-exitViolet                       {color: var(--colorTheme_Violet);}
.UniBOHeader__authData-exitSky                          {color: var(--colorTheme_Sky);}

/* page: bo → body */
@media (max-width: 1250px) {
    body {zoom: 1.0; overflow-x: hidden;}
    .UniBOBody__leftBlock {margin-bottom: 20px;}
    .UniBOBody__rightBlock {width: 100%;}
    .UniBOBody__mainBlock > div {display: block;}
    .UniBOBody__mainBlock > .UniBOBody__littleMenu  {display: inline-block;text-align: center; margin-bottom: 20px;}
}



















































